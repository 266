<template>
    <div>
      <Header />
      <div class="container mx-auto my-10 flex">
        <div class="flex flex-1 flex-col p-4 border border-grey-2 rounded-xl ml-2">
            <div class="py-2 px-2">
                <TransactionUnpaid v-if="Object.keys(selectedTrx) != 0 && selectStatus == '0'" :dataTrx="selectedTrx" @cekStatus="handleCekStatus"/>
                <TransactionProcess v-else-if="Object.keys(selectedTrx) != 0 && selectStatus == '10'" :dataTrx="selectedTrx" @cekStatus="handleCekStatus"/>
                <TransactionFinished v-else-if="Object.keys(selectedTrx) != 0 && selectStatus == '11'" :dataTrx="selectedTrx" />
                <TransactionCancelled v-else-if="Object.keys(selectedTrx) != 0 && selectStatus == '4'" :dataTrx="selectedTrx" />
                <div v-else class="text-center font-bold text-xl">
                    No Transaction Data Found.
                </div>
            </div>
        </div>
      </div>
      <Footer />
    </div>
</template>

<script>
  import Header from '@/components/Header';
  import Footer from '@/components/Footer';
  import TransactionUnpaid from '@/components/TransactionUnpaid';
  import TransactionProcess from '@/components/TransactionProcess';
  import TransactionFinished from '@/components/TransactionFinished';
  import TransactionCancelled from '@/components/TransactionCancelled';

  export default {
    name: 'TransactionDetail',
    components: {
      Header,
      Footer,
      TransactionUnpaid,
      TransactionProcess,
      TransactionFinished,
      TransactionCancelled,
    },
    data: () => ({
      listTrx: [],
      selectedTrx: {},
      selectStatus: 0,
      typeTrip: 'UATSAFARI',
    }),
    computed: {
    },
    async created () {
        
        this.$store.commit("setOverlayLoading", true);
        try {
            // const res2 = await this.$http.post( `${this.$apiSaeboTWV2}/ListOrderV3`, {
            //     "id"    : "UATPESAN",
            //     "cari"  : this.$route.params.nobukti
            // });
            const res = await this.$http.post( `${this.$apiTripweWeb}/transaction/order/search`, {
                "search"  : this.$route.params.nobukti
            });
            if(res.data.status == 200){
                var data = res.data.data;
                this.selectedTrx = data;
                if(data.status == "4" || data.status == "3" || data.status == "12"){
                    this.selectStatus = '4';
                }else if(data.status == "1" && data.status_cair == "0"){
                    this.selectStatus = '10';
                }else if(data.status == "1" && data.status_cair == "1"){
                    this.selectStatus = '11';
                }
            }
        } catch (error) {
            this.$toasted.global.error(error);
        } finally {
            this.$store.commit("setOverlayLoading", false);
        }
    },
    methods: {
        handleCekStatus(data) {
            let trip = '';
            if(data.tipe == 'RENTAL'){
                trip = 'rental';
            }else if(data.tipe == 'OFFROAD'){
                trip = 'offroad';
            }else if(data.tipe == 'ONROAD'){
                trip = 'onroad';
            }else if(data.tipe == 'SKY'){
                trip = 'sky';
            }else{
                trip = 'safari';
            }
            if (data.status == "4" || data.status == "3" || data.status == "12") {
                this.$router.push({
                    name: "Invoice",
                    params: { slug: "cancel", trip: trip },
                });
            } else if (data.status == "1" && data.status_cair == "0") {
                this.$router.push({
                    name: "Invoice",
                    params: { slug: "process", trip: trip },
                });
            } else if (data.status == "0" && data.status_cair == "0") {
                this.$router.push({
                    name: "Invoice",
                    params: { slug: "unpaid", trip: trip },
                });
            } else if (data.status == "1" && data.status_cair == "1") {
                this.$router.push({
                    name: "Invoice",
                    params: { slug: "finish", trip: trip },
                });
            }
        },
    }
  }
</script>